footer {
    background-color: var(--topo-red);
    color: var(--white);
    font-family: 'Lato', sans-serif;
    padding: 2em 1em 1em;
}

.footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-logo {
    padding: 1em;
}

a.footer-link {
    color: var(--white);
    transition: all 0.2s ease;
}
a.footer-link:hover {
    color: var(--black);
}

.data-request {
    font-size: .875rem;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5em;
    text-align: center;
}

#data-request-form {
    display: flex;
    flex-direction: column;
    gap: .5em;
}

#data-request-form input[type=email] {
    font-size: .9rem;
}

input[type=email], input[type=text] {
    border: 1px solid #ccc;
    border-radius: 3rem;
    font-family: "FedraSerifProBNormal", serif;
    font-size: 1rem;
    margin: .25em 0;
    padding: 10px;
}
.data-button {
    font-family: "Lato", sans-serif;
    padding: .75em 1.5em;
    border-radius: 2rem;
    border: 1px solid #9c8888;
    background-color: var(--topo-yellow);
    color: var(--topo-red);
    font-size: .8rem;
    font-weight: 800;
    cursor: pointer;
    box-shadow: 0px 1px 1px rgba(140, 140, 140, 0.5);
    transition: all 0.2s ease;
}
.data-button:hover {
    color: var(--topo-red);
    background-color: var(--white); 
}

.legal-text {
    text-align: center;
    font-size: 0.75rem;
    padding-top: 1em;
}


@media screen and (min-width: 768px) {
    .footer-content {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .footer-text {
        font-size: 1rem;
    }
}