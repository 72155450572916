.product-container {
    padding: 0 2em;
    margin-bottom: 1em;
    z-index: 10;
}
.product-text {
    position: relative;
    z-index: 10;
}
.product-text h5 {
    font-family: "Lato", sans-serif;
    font-weight: 800;
    font-size: 1.75rem;
    line-height: 1;
}
.product-description {
    margin-top: 1em;
    background-color: #6161FF; /* Variation on var(--blueberry) for contrast accessibility */
    color: var(--white); 
    padding: .75em 1em 1em;
    border-radius: 1rem;
    text-shadow: 0 0 1px var(--blueberry);
    font-size: 1rem;
    position: relative;
    display: inline-block;
}
.product-image img {
    width: 100%;
    padding: 2em;
}
.enter-button {
    font-family: "Lato", sans-serif;
    margin-top: 1.5em;
    border: none;
    padding: 1.5em 3em;
    border-radius: 2rem;
    border: 1px solid #9c8888;
    background-color: var(--topo-red);
    color: var(--white);
    font-size: 0.625rem;
    font-weight: 800;
    cursor: pointer;
    box-shadow: 0px 1px 1px rgba(140, 140, 140, 0.5);
    transition: all 0.2s ease;
}
.enter-button:hover {
    background-color: var(--topo-yellow);
    color: var(--topo-red);
}

.enter-button.out-of-stock {
    background-color: black;
    color: white;
    cursor: not-allowed;
}

.tooltip-trigger {
    position: relative;
    border-bottom: 1px dotted #fff;
    cursor: help;
}
.tooltip-trigger:after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    padding: 10px;
    background-color: rgba(37, 37, 37, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    z-index: 1;
}
.tooltip-trigger::before {
    content: "";
    position: absolute;
    bottom: 80%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: rgba(37, 37, 37, 0.8) transparent transparent transparent;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip-trigger:hover::after,
  .tooltip-trigger:hover::before {
    opacity: 1;
  }

@media screen and (min-width:768px) {
    .product-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2em;
    }
    .product-description {
        max-width: 600px;
        padding: 1em;
        font-size: 1.125rem;
    }
    .product-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 2em;
    }
    .product-image {
        max-width: 600px;
    }
    .tooltip-trigger::before {
        bottom: 50%;
    }
    .tooltip-trigger::after {
        width: 400px;
        padding: 12px;
    }
}