.wave-separator {
    width: 100%;
    line-height: 0;
    transform: rotate(180deg);
}

.wave-separator svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 87px;
    transform: translateY(-1px);
}

@media screen and (min-width: 768px) {
    .wave-separator svg {
        width: calc(100% + 1.3px);
        height: 174px;
    }
}