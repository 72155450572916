#merch-section {
    position: relative;
    z-index: 10;
    padding-top: 2em;
    background-color: var(--sabores-yellow);
    background-image: linear-gradient(
        180deg,
        hsl(58deg 100% 50%) 0%,
        hsl(52deg 100% 51%) 32%,
        hsl(47deg 100% 53%) 45%,
        hsl(42deg 100% 54%) 52%,
        hsl(37deg 100% 54%) 56%,
        hsl(33deg 100% 53%) 59%,
        hsl(29deg 100% 52%) 61%,
        hsl(25deg 100% 50%) 63%,
        hsl(19deg 99% 51%) 66%,
        hsl(11deg 97% 53%) 71%,
        hsl(3deg 96% 55%) 81%,
        hsl(355deg 94% 57%) 100%
        );

    h2 {
        color: var(--topo-red);
        font-weight: 800;
        letter-spacing: -1px;
    }
}

#merch-sunburst {
    position: absolute;
    transform: rotate(180deg);
    left: 0;
    top: 25%;
    width: 15%;
    z-index: 0;
}

#merch-swirl {
    position: absolute;
    right: 0;
    top: 65%;
    transform: rotateY(180deg);
    width: 50%;
    z-index: 0;
}

@media screen and (min-width: 768px) {
    .merch-row {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 3em;
    }

    .merch-row.reverse .product-container {
        flex-direction: row-reverse;
    }
    #merch-swirl {
        position: absolute;
        right: 0;
        top: 55%;
        transform: rotateY(180deg);
        width: 20%;
    }
}