#hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
    font-family: "Lato", sans-serif;
    position: relative;
    overflow: hidden;
}


/* Background Elements */
.background-el {
  position: absolute;
  z-index: 10;
}

#swirl {
  top: 0;
  left: 0;
  width: 20%;
}
#sunburst {
  top: 200px;
  right: 0;
  width: 20%;
}
#bubble-1 {
  top: 20%;
  left: 80%;
  width: 4%;
}
#bubble-2 {
  top: 20%;
  left: 20%;
  width: 4%;
}
#red-star {
  top: 95%;
  right: 1%;
  width: 10%;
  z-index: 1001;
}
#cloud {
  top: 55%;
  left: 0;
  width: 40%;
}
#star-1 {
  top: 10%;
  left: 4%;
  width: 4%;
}
#star-2 {
  top: 0;
  left: 35%;
  width: 4%;
}
#star-3 {
  top: -6%;
  right: 35%;
  width: 4%;
}
#star-4 {
  top: 5%;
  right: 5%;
  width: 4%;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 1em 2em;
    z-index: 100;
}

.logo-container img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
  

  @media screen and (min-width: 768px) {
    .logo-container {
        max-width: 600px;
    }
    #bubble-1 {
      top: 15%;
      width: 4%;
    }
    #bubble-2 {
      width: 4%;
    }
    #red-star {
      top: 86%;
      width: 10%;
    }
    #cloud {
      top: 65%;
      width: 30%;
    }
    #star-1 {
      top: 11%;
      width: 2%;
    }
    #star-2 {
      width: 2%;
    }
    #star-3 {
      top: -4%;
      width: 2%;
    }
    #star-4 {
      width: 2%;
    }
  }