#app {
  min-height: 100dvh;
  height: 100%;
  width: 100%;
  background-color: var(--sabores-yellow);
  font-family: "Neuton", sans-serif;
}

/* Importing Topo Nueva VF */
@font-face {
  font-family: 'TopoNuevaVF';
  src: url('./fonts/TopoNuevaVF.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Importing Fedra Serif Pro B Normal */
@font-face {
  font-family: 'FedraSerifProBNormal';
  src: url('./fonts/fedraserifpro b normal.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
