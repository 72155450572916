.terms-and-conditions {
    margin: 0 2.5em;
}

.center {
    text-align: center;
}

.indent {
    margin-left: 1em;
}

.terms-and-conditions p {
    margin: .5em 0;
}

@media screen and (min-width: 768px) {
    .terms-and-conditions {
        margin: 0 5em;
    }
}