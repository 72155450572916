#lookbook-section {
    background-color: var(--orange-wave);
}

.swiper-container {
  padding: 1em;
  margin-bottom: 1em;
}

.swiper-pagination-bullet {
    background-color: var(--white);
}

.swiper-button-prev, .swiper-button-next {
    width: 10%;
    height: auto;
    z-index: 1000;
    cursor: pointer;
  }

  .swiper-button-prev {
    transform: translateX(100%);
  }
  .swiper-button-next {
    transform: translateX(-100%);
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  .swiper-navigation {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
  }

  /* Lightbox */
  .lightbox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    padding: .5em;
  }

  .lightbox-content {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .lb-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .close {
    position: absolute;
    top: -80px;
    right: 0;
    color: white;
    cursor: pointer;
    font-size: 5rem;
    z-index: 1002;
    transition: all 0.2s ease;
  }
  .close:hover {
    color: var(--sabores-red);
  }

@media screen and (min-width: 768px) {
  .swiper-button-prev, .swiper-button-next {
    width: 4%;
    height: auto;
    z-index: 1000;
    cursor: pointer;
  }
  .swiper-button-prev {
    transform: translateX(200%);
  }
  .swiper-button-next {
    transform: translateX(-200%);
  }
  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    transform: translateY(16px);
  }
  .lb-image-container {
    max-width: 45%;
  }
}
