*{scroll-behavior: smooth;}

#navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1em 0;
    font-family: "TopoNuevaVF", sans-serif;
  }
  
  .nav-links {
    list-style-type: none;
    display: flex;
    align-items: center; 
    justify-content: center; 
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    font-weight: 900;
    margin: 0 1rem;
    transition: all 0.3s ease-in-out; 
  }
  .nav-links li:hover {
    transform: scale(1.3);
  }
  
  .nav-links a {
    text-decoration: none;
    color: var(--black);
    font-size: 1rem; 
  }
  
  .header-icon {
    display: flex; 
    align-items: center;
  }
  
  .header-icon.left img {
    max-width: 20px;
    height: auto;
  }
  .header-icon.right img {
    max-width: 60px;
    height: auto;
  }
  
  @media screen and (min-width: 768px) {
    .nav-links a {
      font-size: 2rem;
    }
    .nav-links li {
        margin: 0 3em;
    }
    .header-icon {
        padding: 0 2em;
    }
    .header-icon.left img {
        max-width: 60px;
        height: auto;
      }
      .header-icon.right img {
        max-width: 120px;
        height: auto;
      }
  }