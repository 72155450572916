#main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

/* Section Globals */
section {
  padding: 0 1.5em;
}

section h2 {
  font-weight: 600;
  padding: .5em 0;
  transform: scaleY(1.2);
}

.section-title {
  color: white;
  font-size: 2rem;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  section {
    padding: 0 3em;
  }
  section h2 {
    padding: .5em 1em;
  }
 
  .section-title {
    font-size: 4rem;;
  }
}

/* Animations */

@keyframes floatPop {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  90% {
    transform: translateY(-400px) scale(1); /* Float up */
    opacity: 1;
  }
  95% {
    transform: translateY(-400px) scale(1.4); /* Pop */
    opacity: 0;
  }
  100% {
    transform: translateY(-400px) scale(0); 
    opacity: 0;
  }
}
