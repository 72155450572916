.Runner {
    background-color: var(--sabores-red);
    color: var(--black);
    top: 0;
    width: 100%;
    overflow: hidden;
}

.tickerWrapper {
    display: flex;
    height: 30px;
    line-height: 30px;
    animation: tickerScroll 14s linear infinite;
}

.tickerWrapper:hover {
    cursor: pointer;
    animation-play-state: paused;
}

.tickerText {
    white-space: nowrap;
    color: var(--black);
    font-family: 'TopoNuevaVF', sans-serif;
    font-weight: 600;
    font-size: .75rem;
    letter-spacing: 1px;
}

@keyframes tickerScroll {
    0% { transform: translateX(0); }

    100% { transform: translateX(-100%); }
  }