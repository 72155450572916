body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --black: #000000;
  --white: #FFFFFF;
  --sabores-yellow: #FFF600;
  --topo-yellow: #FFD100;
  --sabores-red: #F82B3D;
  --topo-red: #EA0029;
  --tangerine: #FF6600;
  --ginger: #FFAB19;
  --orange-wave: #ff7a00;
  --lime: #33CC00;
  --mint: #D1EB55; 
  --blueberry: #6666ff;
  --hibiscus: #A2AFFF;
}

/*
  Josh Comeau's CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
  margin: 0;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}