#flavor-section {
    background-color: var(--sabores-yellow);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3em 0 1em;
    overflow: hidden;
}

.flavor-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
}

.flavor-text {
    width: 75%;
    margin: 0 auto;
}

.cans-container {
    margin: 0 auto;
    width: 100%;
    max-width: 350px;
}

.discover-text {
    font-family: "Neuton", sans-serif;
    text-align: center;
    padding: 1em 0;
}

.discover-text p {
    font-family: 'FedraSerifProBNormal', sans-serif;
    font-size: .7rem;
    padding: 0 .25em;
}
.discover-hed {
    margin: 1em 0;
    width: 100%;
    margin-bottom: 1em;
}
.discover-hed p {
    line-height: .75;
    font-family: "Neuton", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: var(--topo-red);
    min-width: 300px;
}

#topo-button {
    font-family: "Lato", sans-serif;
    border: none;
    padding: .5em 1.5em;
    border-radius: 3rem;
    background-color: var(--topo-red);
    color: var(--white);
    font-size: 1.25rem;
    font-weight: 700;
    margin: 1em 0 2em;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
#topo-button:hover {
    background: var(--topo-yellow);
    color: var(--sabores-red);
    border: 1px inset var(--sabores-red);
}

/* Background Elements */

#flavor-bubble-1 {
    bottom: 18%;
    right: 10%;
    width: 12%;
}
#flavor-bubble-2 {
    bottom: 5.5%;
    left: 5%;
    width: 6%;
}
#flavor-bubble-3 {
    bottom: 6.5%;
    right: 3%;
    width: 3%;
}
#flavor-star-1 {
    bottom: 16%;
    right: 14%;
    width: 8%;
}
#flavor-star-2 {
    bottom: 14%;
    left: 7%;
    width: 5%;
}
#flavor-star-3 {
    bottom: 10%;
    left: 3%;
    width: 2%;
}
#orange {
    bottom: 2%;
    left: 4%;
    width: 10%;
}
#lime {
    bottom: 18.5%;
    left: 9%;
    width: 12%;
}
#blueberry {
    bottom: 5%;
    right: 5%;
    width: 10%;
}


/* ref bubbles */
.bubble {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    pointer-events: none;
    box-shadow: inset 0 0 10px rgba(102, 102, 255, 0.5);
    animation: floatPop 1.5s linear forwards;
    z-index: 101;
  }
  
  .bubble span {
    position: absolute;
    border-radius: 50%;
  }
  
  .bubble span:nth-child(1) {
    inset: 5px;
    border-left: 4px solid rgba(162, 175, 255, 0.5);
    filter: blur(1px);
  }


@media screen and (min-width: 300px) {
    .discover-hed p {
        font-size: 1.5rem;
    }
}  

@media screen and (min-width: 768px) {
    .flavor-row {
        width: 80%;
        flex-direction: row;
    }
    .flavor-text {
        width: 40%;
    }
    .discover-hed p {
        font-size: 2rem;
    }
    .discover-text {
        text-align: center;
        margin: 0 auto;
    }
    .discover-text h6 {
        max-width: 350px;
    }
    .discover-text p {
        max-width: 400px;
    }
    /* BACKGROUND ELEMENTS */
    #flavor-bubble-1 {
        bottom: 11.5%;
        left: 35%;
        width: 5%;
    }
    #flavor-bubble-2 {
        bottom: 4.5%;
        left: 31%;
        width: 3%;
    }
    #flavor-bubble-3 {
        bottom: 4.5%;
        right: 10%;
        width: 2%;
    }
    #flavor-star-1 {
        bottom: 12%;
        right: 10%;
        width: 3%;
    }
    #flavor-star-2 {
        bottom: 4.5%;
        left: 20%;
        width: 3%;
    }
    #flavor-star-3 {
        bottom: 10%;
        left: 52%;
        width: 1%;
    }
    #orange {
        bottom: 2%;
        left: 10%;
        width: 8%;
    }
    #lime {
        bottom: 12%;
        left: 9%;
        width: 8%;
    }
    #blueberry {
        bottom: 3.5%;
        right: 20%;
        width: 4%;
    }
    
}

