.episode-container {
    margin: 1em 0 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tv {
    padding: 1em;
    position: relative;
    width: 100%;
    margin-bottom: 1em;
  }

  .video-container {
    position: relative;
    z-index: 100;
  }

  .tv-video {
    position: absolute;
    top: 9.5%;
    left: 5%;
    width: 90%;
    height: auto;
    z-index: 2;
    border-radius: 2px;
    cursor: pointer;
  }

  .tv-frame {
    width: 100%;
    height: auto;
    z-index: 1;
  }

  .episode-description {
    display: grid;
    grid-template-columns: 45% 55%;
    align-items: center;
    justify-items: center;
    background-color: #ED070F; /* Variation on var(--sabores-red) for contrast accessibility */
    color: var(--black);
    margin: 2em 0;
    padding: .5em 0;
    border-radius: 2.5rem;
    z-index: 3;
    font-family: 'FedraSerifProBNormal', sans-serif;
  }
  
  .episode-description > p:first-of-type {
    font-family: "Neuton", sans-serif;
    padding-left: .25em;
    font-size: 3.5rem;
    font-weight: 600;
    margin-bottom: .25em;
    color: var(--sabores-yellow);
  }

  .episode-description p {
    line-height: 1.3;
    padding: 0.625rem;
    color: var(--white);
    font-size: .75rem;
    text-shadow: 0 0 1px var(--sabores-red); /* readability */
  }

  .placeholder-tv {
    position: relative;
  }

  .placeholder-tv img {
    width: 30%;
    margin: 0 auto;
    opacity: .5;
  }

  .placeholder-tv p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    font-family: "Lato", sans-serif;
  }
  

  @media screen and (min-width: 480px) {
    .episode-description {
      max-width: 960px;
      padding: 1em 2em;
      grid-template-columns: 35% 65%;
    }
    .episode-description > p:first-of-type {
      padding-right: .5em;
      font-size: 2.75rem;
    }

  }

  @media screen and (min-width: 768px) {
    .tv {
      max-width: 800px ;
    }
    .episode-description > p:first-of-type {
      font-size: 5rem;
    }
    .episode-description p {
      padding: 0;
      font-size: .9rem;
    }
  }